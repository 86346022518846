import { useLazyQuery, useMutation } from '@apollo/client';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import {
  SortableContext,
  horizontalListSortingStrategy,
  useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Form, Tabs } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  PAGE_TYPES,
  STATUS_TYPES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../../common/constants';
import {
  messageContext,
  modalContext
} from '../../../../components/AppComponentContainer';
import RouterPromptModal from '../../../../components/RouterPromptModal';
import useCheckPermission from '../../../../hooks/useCheckPermission';
import {
  CREATE_COLLECTION,
  DELETE_COLLECTION,
  UPDATE_COLLECTION,
  UPDATE_COLLECTION_ORDER,
  UPSERT_COLLECTION_ITEMS
} from '../graphql/Mutations';
import { GET_COLLECTION_ITEMS } from '../graphql/Queries';
import CollectionItems from './CollectionItems';

const AddEditCollectionItems = ({
  collectionId,
  isEdit,
  parentCollectionSlug
}) => {
  const [form] = Form.useForm();
  const [activeKey, setActiveKey] = useState('0');
  const [showPrompt, setShowPrompt] = useState(false);
  const [isFormValueChanged, setIsFormValueChanged] = useState(false);
  const [newActiveKey, setNewActiveKey] = useState(null);
  const addSubCollectionRef = useRef(null);

  const DEFAULT_SUB_COLLECTION = {
    title: 'Default',
    description: '',
    slug: `${parentCollectionSlug}-default`,
    status: STATUS_TYPES.DRAFT,
    collectionItems: [{ type: 'VIDEO', itemId: null, sequence: null }]
  };

  const initialValues = {
    ...DEFAULT_SUB_COLLECTION
  };

  const getSubCollections = Form.useWatch('subCollections', form) ?? [];
  const filteredSubCollection = getSubCollections?.filter((item) => item?.id);
  const labelValue = useMemo(
    () => getSubCollections?.[activeKey]?.title ?? 'Default',
    [getSubCollections, activeKey]
  );
  const items = useMemo(
    () => getSubCollections?.[activeKey]?.collectionItems ?? [],
    [getSubCollections, activeKey]
  );
  const isSubCollectionAdding =
    filteredSubCollection?.length !== getSubCollections?.length;
  const initialTabItems = useMemo(
    (loading, itemsLoading, updateCollectionLoading) => [
      {
        label: 'Default',
        children: (
          <CollectionItems
            form={form}
            items={items}
            loading={loading}
            itemsLoading={itemsLoading}
            updateCollectionLoading={updateCollectionLoading}
          />
        ),
        key: '0',
        closable: false
      }
    ],
    [form, items]
  );

  const [tabItems, setTabItems] = useState(initialTabItems);
  const onChange = (newKey) => {
    setNewActiveKey(newKey);
    const isSaved = !!getSubCollections?.[activeKey]?.id;

    if (!isFormValueChanged) {
      if (isSaved) {
        setActiveKey(newKey);
      } else {
        setShowPrompt(true);
      }
    } else {
      setShowPrompt(true);
    }
  };

  const [
    fetchSubCollectionDetails,
    { loading: fetchingDetails }
  ] = useLazyQuery(GET_COLLECTION_ITEMS, {
    fetchPolicy: 'network-only'
  });

  const [addCollection, { loading }] = useMutation(CREATE_COLLECTION);
  const [updateCollection, { loading: updateCollectionLoading }] = useMutation(
    UPDATE_COLLECTION
  );

  const [deleteCollection] = useMutation(DELETE_COLLECTION);
  const [upsertCollectionItems, { loading: itemsLoading }] = useMutation(
    UPSERT_COLLECTION_ITEMS,
    {
      onError() {}
    }
  );
  const [updateCollectionOrder, { loading: updateOrderLoading }] = useMutation(
    UPDATE_COLLECTION_ORDER
  );
  const getItemData = (data, type) => {
    switch (type) {
      case PAGE_TYPES.FORM:
        return {
          label: data?.title,
          value: data?.formId
        };
      case PAGE_TYPES.ARTICLE:
        return {
          id: data?.articleId,
          title: data?.title,
          url: data?.imageThumbnail?.url ?? ''
        };
      case PAGE_TYPES.PODCAST:
        return {
          id: data?.podcastId,
          title: data?.title,
          url: data?.imageThumbnail?.url ?? ''
        };
      default:
        return {
          id: data?.videoId,
          title: data?.title,
          url: data?.imageThumbnail?.url ?? ''
        };
    }
  };

  useEffect(() => {
    if (isEdit && !!collectionId) {
      fetchSubCollectionDetails({
        variables: {
          where: {
            id: collectionId
          },
          filter: {
            pagination: false
          }
        }
      })
        .then(({ data }) => {
          const subCollections = data?.collectionItemsAdmin?.collectionItems?.map(
            (subCollection) => ({
              ...subCollection?.itemData,
              slug: subCollection?.itemData?.slug?.startsWith('/')
                ? subCollection?.itemData?.slug?.substring(1)
                : `/${subCollection?.itemData?.slug}`
            })
          );
          fetchSubCollectionDetails({
            variables: {
              where: {
                id:
                  data?.collectionItemsAdmin?.collectionItems?.[0]?.itemData?.id
              },
              filter: {
                pagination: false
              }
            }
          })
            .then(({ data: subCollectionItems }) => {
              const subCollectionItemsData =
                subCollectionItems?.collectionItemsAdmin?.collectionItems;
              if (subCollections && subCollectionItemsData) {
                form?.setFieldsValue({
                  subCollections
                });
                const transformedItems = subCollectionItemsData?.map((item) => {
                  return {
                    type: item?.type,
                    sequence: item?.sequence ?? null,
                    itemId: getItemData(item?.itemData, item?.type)
                  };
                });
                form?.setFieldValue(
                  ['subCollections', 0, 'collectionItems'],
                  transformedItems
                );
              }
            })
            .catch();
        })
        .catch();
    }
  }, [collectionId, isEdit, form]);

  useEffect(() => {
    const getSubCollectionItems = form?.getFieldValue(['subCollections']);
    const subCollectionId = getSubCollectionItems?.[activeKey]?.id;
    if (subCollectionId) {
      fetchSubCollectionDetails({
        variables: {
          where: {
            id: subCollectionId
          },
          filter: {
            pagination: false
          }
        }
      })
        .then(({ data: subCollectionItems }) => {
          const subCollectionItemsData =
            subCollectionItems?.collectionItemsAdmin?.collectionItems;
          if (subCollectionItemsData) {
            const transformedItems = subCollectionItemsData?.map((item) => {
              return {
                type: item?.type,
                sequence: item?.sequence ?? null,
                itemId: getItemData(item?.itemData, item?.type)
              };
            });
            form?.setFieldValue(
              ['subCollections', activeKey, 'collectionItems'],
              transformedItems
            );
          }
        })
        .catch();
    }
  }, [activeKey, form]);

  useEffect(() => {
    if (tabItems?.length > 0) {
      const updatedItems = tabItems?.map((item) => {
        if (item?.key === activeKey) {
          return {
            ...item,
            label: labelValue
          };
        }
        return { ...item };
      });
      setTabItems(updatedItems);
    }
  }, [labelValue, activeKey]);

  const handleSubmit = (values) => {
    const findIndexData = values?.subCollections?.[activeKey];
    const subCollectionItems = {
      id: findIndexData?.id,
      title: findIndexData?.title,
      description: findIndexData?.description,
      slug: findIndexData?.slug?.startsWith('/')
        ? findIndexData?.slug?.substring(1)
        : findIndexData?.slug,
      status: findIndexData?.status
    };

    const isSubCollectionEdit = !!subCollectionItems?.id;
    const collectionItems = findIndexData?.collectionItems?.map(
      (item, index) => ({
        ...item,
        sequence: item?.sequence ?? null,
        itemId: item?.itemId?.id ?? item?.itemId?.value,
        order: index + 1
      })
    );

    const isCollectionItems = collectionItems?.filter((item) => item?.itemId);
    if (isSubCollectionEdit) {
      updateCollection({
        variables: {
          ...(isSubCollectionEdit && { id: subCollectionItems?.id }),
          data: {
            title: subCollectionItems?.title,
            status: subCollectionItems?.status,
            slug: subCollectionItems?.slug,
            order: subCollectionItems?.order,
            description: subCollectionItems?.description
          }
        }
      })
        .then(() => {
          setShowPrompt(false);
          setIsFormValueChanged(false);
          if (isCollectionItems?.length >= 1 && collectionId) {
            upsertCollectionItems({
              variables: {
                where: {
                  id: collectionId
                },
                data: {
                  items: [
                    {
                      itemId: subCollectionItems?.id,
                      collectionItems: isCollectionItems
                    }
                  ]
                }
              }
            });
          }
        })
        .catch();
      if (collectionId) {
        fetchSubCollectionDetails({
          variables: {
            where: {
              id: collectionId
            },
            filter: {
              pagination: false
            }
          }
        })
          .then(({ data: subCollectionData }) => {
            const subCollections = subCollectionData?.collectionItemsAdmin?.collectionItems?.map(
              (subCollection) => ({
                ...subCollection?.itemData,
                slug: subCollection?.itemData?.slug?.startsWith('/')
                  ? subCollection?.itemData?.slug?.substring(1)
                  : `/${subCollection?.itemData?.slug}`
              })
            );

            fetchSubCollectionDetails({
              variables: {
                where: {
                  id:
                    subCollectionData?.collectionItemsAdmin?.collectionItems?.[
                      activeKey
                    ]?.itemData?.id
                },
                filter: {
                  pagination: false
                }
              }
            })
              .then(({ data: listCollectionItems }) => {
                const subCollectionItemsData =
                  listCollectionItems?.collectionItemsAdmin?.collectionItems;
                const singleSubCollectionData = subCollections?.[activeKey];
                if (subCollections && subCollectionItemsData) {
                  form?.setFieldValue(
                    ['subCollections', activeKey],
                    singleSubCollectionData
                  );
                  const transformedItems = subCollectionItemsData?.map(
                    (item) => {
                      return {
                        type: item?.type,
                        sequence: item?.sequence ?? null,
                        itemId: getItemData(item?.itemData, item?.type)
                      };
                    }
                  );
                  form?.setFieldValue(
                    ['subCollections', activeKey, 'collectionItems'],
                    transformedItems
                  );
                }
              })
              .catch();
          })
          .catch();
      }
    } else {
      addCollection({
        variables: {
          data: {
            parentCollectionId: collectionId,
            title: subCollectionItems?.title,
            status: subCollectionItems?.status,
            slug: subCollectionItems?.slug,
            description: subCollectionItems?.description
          }
        }
      })
        .then(({ data }) => {
          setShowPrompt(false);
          setIsFormValueChanged(false);
          if (isCollectionItems?.length >= 1 && collectionId) {
            upsertCollectionItems({
              variables: {
                where: {
                  id: collectionId
                },
                data: {
                  items: [
                    {
                      itemId: data?.createCollection?.collection?.id,
                      collectionItems: isCollectionItems
                    }
                  ]
                }
              }
            });
          }
          if (collectionId) {
            fetchSubCollectionDetails({
              variables: {
                where: {
                  id: collectionId
                },
                filter: {
                  pagination: false
                }
              }
            })
              .then(({ data: subCollectionData }) => {
                const subCollections = subCollectionData?.collectionItemsAdmin?.collectionItems?.map(
                  (subCollection) => ({
                    ...subCollection?.itemData,
                    slug: subCollection?.itemData?.slug?.startsWith('/')
                      ? subCollection?.itemData?.slug?.substring(1)
                      : `/${subCollection?.itemData?.slug}`
                  })
                );

                fetchSubCollectionDetails({
                  variables: {
                    where: {
                      id:
                        subCollectionData?.collectionItemsAdmin
                          ?.collectionItems?.[activeKey]?.itemData?.id
                    },
                    filter: {
                      pagination: false
                    }
                  }
                })
                  .then(({ data: listCollectionItems }) => {
                    const subCollectionItemsData =
                      listCollectionItems?.collectionItemsAdmin
                        ?.collectionItems;
                    const singleSubCollectionData = subCollections?.[activeKey];
                    if (subCollections && subCollectionItemsData) {
                      form?.setFieldValue(
                        ['subCollections', activeKey],
                        singleSubCollectionData
                      );
                      const transformedItems = subCollectionItemsData?.map(
                        (item) => {
                          return {
                            type: item?.type,
                            sequence: item?.sequence ?? null,
                            itemId: getItemData(item?.itemData, item?.type)
                          };
                        }
                      );
                      form?.setFieldValue(
                        ['subCollections', activeKey, 'collectionItems'],
                        transformedItems
                      );
                    }
                  })
                  .catch();
              })
              .catch();
          }
        })
        .catch();
    }
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10
    }
  });

  const handleCancel = () => {
    setShowPrompt(false);
    addSubCollectionRef.current = null;
  };

  const handleOK = () => {
    setShowPrompt(false);
    const isId = getSubCollections?.[activeKey]?.id;
    if (isId && collectionId) {
      fetchSubCollectionDetails({
        variables: {
          where: {
            id: collectionId
          },
          filter: {
            pagination: false
          }
        }
      })
        .then(({ data: subCollections }) => {
          setIsFormValueChanged(false);
          if (newActiveKey) {
            setActiveKey(newActiveKey);
          } else if (addSubCollectionRef?.current) {
            addSubCollectionRef?.current({ ...DEFAULT_SUB_COLLECTION });
            setActiveKey(getSubCollections?.length?.toString());
          }
          const singleCollection =
            subCollections?.collectionItemsAdmin?.collectionItems?.[activeKey]
              ?.itemData;
          form?.setFieldValue(['subCollections', activeKey], singleCollection);
        })
        .catch();
    } else {
      setIsFormValueChanged(false);
      getSubCollections?.pop(activeKey);
      if (newActiveKey) {
        setActiveKey(newActiveKey);
      } else if (addSubCollectionRef?.current) {
        addSubCollectionRef.current({ ...DEFAULT_SUB_COLLECTION });
        setActiveKey(getSubCollections?.length?.toString());
      }
    }
  };

  const onChangeForm = () => {
    setIsFormValueChanged(true);
  };

  const DraggableTabNode = ({ className, ...props }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition
    } = useSortable({
      id: props['data-node-key'],
      disabled: isSubCollectionAdding
    });
    const style = {
      ...props?.style,
      transform: CSS?.Translate?.toString(transform),
      transition,
      cursor: 'move'
    };
    return React?.cloneElement(props?.children, {
      ref: setNodeRef,
      style,
      ...attributes,
      ...listeners
    });
  };

  const onEdit = (add, remove) => (targetKey, action) => {
    const getSubCollectionItems = form?.getFieldValue(['subCollections']);
    if (action === 'add') {
      if (isFormValueChanged) {
        setShowPrompt(true);
        setNewActiveKey(null);
        addSubCollectionRef.current = add;
      } else if (!isSubCollectionAdding) {
        add({ ...DEFAULT_SUB_COLLECTION });
        setActiveKey(getSubCollections?.length?.toString());
      } else {
        messageContext?.success(
          'You already have added one sub-collection. Please create it to add more.'
        );
      }
    } else {
      modalContext?.confirm({
        title: 'Are you sure, you want to delete this subCollection?',
        centered: true,
        okText: 'Yes',
        cancelText: 'No',
        okType: 'primary',
        onOk() {
          const removedIndex = +targetKey;
          setShowPrompt(false);
          const subCollectionId = getSubCollectionItems?.[removedIndex]?.id;
          if (subCollectionId) {
            deleteCollection({
              variables: {
                id: subCollectionId
              }
            }).then(() => {
              remove(removedIndex);
              if (+activeKey === removedIndex) {
                const prevIndex = removedIndex - 1;
                const nextIndex = removedIndex + 1;
                setIsFormValueChanged(false);
                if (getSubCollections?.[prevIndex]) {
                  setActiveKey(prevIndex?.toString());
                } else if (getSubCollections?.[nextIndex]) {
                  setActiveKey(removedIndex?.toString());
                }
              } else if (+activeKey > removedIndex) {
                setActiveKey((+activeKey - 1)?.toString());
              }
            });
          } else {
            remove(removedIndex);
            if (+activeKey === removedIndex) {
              const prevIndex = removedIndex - 1;
              const nextIndex = removedIndex + 1;
              setIsFormValueChanged(false);
              if (getSubCollections?.[prevIndex]) {
                setActiveKey(prevIndex?.toString());
              } else if (getSubCollections?.[nextIndex]) {
                setActiveKey(removedIndex?.toString());
              }
            } else if (+activeKey > removedIndex) {
              setActiveKey((+activeKey - 1)?.toString());
            }
          }
        }
      });
    }
  };

  return (
    <div className="add-edit-sub-collection-items">
      <Form
        className="add-edit-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          subCollections: [
            { ...initialValues, slug: `${parentCollectionSlug}-default` }
          ]
        }}
        onChange={onChangeForm}
        disabled={
          isViewOnly ||
          fetchingDetails ||
          updateCollectionLoading ||
          itemsLoading ||
          loading ||
          updateOrderLoading
        }
      >
        <fieldset className="mb-12">
          <legend className="role-legend">Items</legend>
          <Form.List name={['subCollections']}>
            {(fields, { move, add, remove }) => (
              <Tabs
                activeKey={activeKey}
                type="editable-card"
                items={fields?.map((field) => ({
                  label: getSubCollections?.[field?.name]?.title || 'Default',
                  children: (
                    <CollectionItems
                      form={form}
                      items={items}
                      updateCollectionLoading={updateCollectionLoading}
                      parentCollectionSlug={parentCollectionSlug}
                      field={field}
                    />
                  ),
                  key: field?.name?.toString(),
                  closable: filteredSubCollection?.length > 1
                }))}
                onEdit={onEdit(add, remove)}
                onChange={onChange}
                renderTabBar={(tabBarProps, DefaultTabBar) => (
                  <DndContext
                    sensors={[sensor]}
                    onDragEnd={({ active, over }) => {
                      const DragId = getSubCollections?.[active?.id]?.id;
                      if (!DragId) {
                        setShowPrompt(true);
                      } else if (active?.id && over?.id && DragId) {
                        move(+active?.id, +over?.id);
                        updateCollectionOrder({
                          variables: {
                            where: {
                              id: DragId
                            },
                            data: {
                              order: +over?.id + 1
                            }
                          }
                        }).catch(() => {
                          move(+over?.id, +active?.id);
                        });
                      }
                    }}
                  >
                    <SortableContext
                      items={fields?.map(({ name }) => name + 1)}
                      strategy={horizontalListSortingStrategy}
                    >
                      <DefaultTabBar {...tabBarProps}>
                        {(node) => (
                          <DraggableTabNode {...node.props} key={node.name}>
                            {node}
                          </DraggableTabNode>
                        )}
                      </DefaultTabBar>
                    </SortableContext>
                  </DndContext>
                )}
              />
            )}
          </Form.List>
        </fieldset>
        <RouterPromptModal
          isPrompt={showPrompt}
          handleCancel={handleCancel}
          handleOK={handleOK}
        />
      </Form>
    </div>
  );
};

export default AddEditCollectionItems;
