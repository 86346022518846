import { Button, Space, Tag, Typography } from 'antd';
import React from 'react';
import Live from '../../../assets/images/live-new.png';
import { status as assetStatus } from '../../../common/utils';
import Image from '../../../components/Image';

const { Text, Paragraph } = Typography;
function CustomCard({
  src,
  alt,
  title,
  heading,
  description,
  primary,
  secondary,
  primaryOnClick,
  secondaryOnClick,
  onClick,
  status,
  languageCode,
  blurHash,
  statusColor = 'green',
  copyUrl = false,
  children,
  isLive,
  isContributor = false,
  subTitle
}) {
  return (
    <div className="cs-card">
      {isLive && <img className="live-tag" src={Live} alt="Live" />}
      {src ? (
        <div onClick={onClick} className="cs-card-img">
          <Image blurHash={blurHash} src={src} alt={alt} />
        </div>
      ) : null}
      <div className="cs-card-description">
        <div>
          <div className="d-flex align-center justify-between mb-4">
            {title ? (
              <Text className="block mb-0 text-tiny text-n-500">{title}</Text>
            ) : null}
            <Space size={5}>
              {assetStatus.READY === status && copyUrl && (
                <Paragraph
                  className="copy-icon"
                  copyable={{
                    text: src,
                    tooltips: 'Copy Image Url'
                  }}
                />
              )}
              {status && (
                <Tag className="m-0 custom-tag" color={statusColor}>
                  {status}
                </Tag>
              )}
              {languageCode && (
                <Tag className="m-0 custom-tag" color="magenta-inverse">
                  {languageCode}
                </Tag>
              )}
            </Space>
          </div>
          {isContributor ? (
            <>
              {heading && description ? (
                <Text className="block mb-4 heading-h6 text-n-500">
                  {heading} {description}
                </Text>
              ) : null}
              {subTitle ? (
                <Text className="text-m mb-20 block card-dc text-n-500">
                  {subTitle}
                </Text>
              ) : null}
            </>
          ) : (
            <>
              {heading ? (
                <Text className="block mb-4 heading-h6 text-n-500">
                  {heading}
                </Text>
              ) : null}
              {description ? (
                <Text className="text-m mb-20 block card-dc text-n-500">
                  {description}
                </Text>
              ) : null}
            </>
          )}

          {children}
        </div>
        <div className="card-button-wrapper">
          {secondary ? (
            <Button
              className="button-l text-n-500 cs-button hb-n-500 hbg-n-500 hc-n-800 bg-transparent b-1 b-solid b-n-500"
              size="small"
              onClick={secondaryOnClick}
            >
              {secondary}
            </Button>
          ) : null}

          {primary ? (
            <Button
              className="button-l text-n-900 cs-button bg-n-500 b-1 b-solid hb-n-500 hbg-n-800 hc-n-500"
              size="small"
              onClick={primaryOnClick}
            >
              {primary}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
export default CustomCard;
